'use client';
import { useLayoutEffect, useEffect } from 'react';

function sortNodes(nodes) {
  return nodes.sort((a, b) => {
    const compare = a.compareDocumentPosition(b);
    if (compare & Node.DOCUMENT_POSITION_FOLLOWING || compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
      return -1;
    }
    if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
      return 1;
    }
    if (compare & Node.DOCUMENT_POSITION_DISCONNECTED || compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC) {
      throw Error("Cannot sort the given nodes.");
    } else {
      return 0;
    }
  });
}
const isElement = (el) => typeof el == "object" && "nodeType" in el && el.nodeType === Node.ELEMENT_NODE;
function getNextIndex(current, max, loop) {
  let next = current + 1;
  if (loop && next >= max)
    next = 0;
  return next;
}
function getPrevIndex(current, max, loop) {
  let next = current - 1;
  if (loop && next < 0)
    next = max;
  return next;
}
const useSafeLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;
const cast = (value) => value;

export { cast, getNextIndex, getPrevIndex, isElement, sortNodes, useSafeLayoutEffect };
