import { jsx } from 'react/jsx-runtime';
import { createIcon } from '@chakra-ui/react';

const SmallAddIcon = createIcon({
  displayName: "SmallAddIcon",
  viewBox: "0 0 20 20",
  path: /* @__PURE__ */ jsx(
    "path",
    {
      fill: "currentColor",
      d: "M14 9h-3V6c0-.55-.45-1-1-1s-1 .45-1 1v3H6c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1z",
      fillRule: "evenodd"
    }
  )
});

export { SmallAddIcon };
